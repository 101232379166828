@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Crimson Pro", sans-serif;
}

html {
  scroll-behavior: smooth;
}

main {
  padding: 0px 32px;
}

ul {
  display: flex;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: white;
  position: relative;
  cursor: pointer;
}

button {
  background-color: #ee5f1d;
  border-radius: 9999px;
  font-weight: 600;
  color: white;
  transition: all 300ms ease;
  cursor: pointer;
  border-style: none;
}

button:hover {
  background-color: #e07a1b;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1);
}

.black {
  color: black;
}

.navy {
  color: #243e63;
}

.purple {
  color: #6415ff;
}

.red {
  color: #ee5f1d;
}

.grey {
  color: rgb(230, 230, 230);
}

.offwhite {
  color: #e6e6e6;
}

.white {
  color: white;
}

.link__hover-effect:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #ee5f1d;
  opacity: 0;
  width: 100%;
  left: 0;
  bottom: -5px;
  transition: all 300ms ease;
}

.link__hover-effect:hover:after {
  opacity: 1;
}

.link__hover-effect--white:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #e5e7eb;
  opacity: 0;
  width: 100%;
  left: 0;
  bottom: -5px;
  transition: all 300ms ease;
}

.link__hover-effect--white:hover:after {
  opacity: 1;
}

.container {
  padding: 96px 0;
}

.row {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

input:focus {
  outline: 0;
}

/* NAV */

.nav__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 40px;
  margin-right: 12px;
}

.logo__wrapper {
  font-weight: 900;
  font-size: 24px;
  transition: all 300ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.6px;
}

.logo__name:hover {
  color: #6415ff;
}

.logo__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav__links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__link {
  margin: 0 24px;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  letter-spacing: 0.4px;
  transition: all 300ms ease;
}

.nav__link--login {
  margin-left: 48px;
  margin-right: 24px;
}

.nav__link--signup {
  font-size: 18px;
  padding: 12px 32px;
}

.nav__link:hover {
  color: #ee5f1d;
}

#landing {
  margin-top: 32px;
}

/* DS MENU */

.menu__container {
  padding: 16px 0px;
  max-width: 1280px;
  margin: 0 auto;
}

.menu__container-carousel {
  padding: 16px 16px;
  max-width: 1280px;
  margin: 0 auto;
}

.slick-prev:before,
.slick-next:before {
  color: #ee5f1d !important;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
}

.menu--section-name {
  font-size: 24px;
  color: #ee5f1d;
  font-weight: 700;
  margin-left: 16px;
  margin-bottom: 16px
}

.menu--section-desc {
  font-size: 16px;
  color: #464646;
  font-weight: 500;
  margin-left: 16px;
  margin-bottom: 16px
}

.menu--box {
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  padding: 16px;
  margin-bottom: 12px;
}

.menu--box-carousel {
  max-width: 250px;
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  padding: 16px;
  margin-bottom: 12px;
}

.menu--item {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 4px;
}

.menu--item-price {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
}

.menu--item-description {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 4px;
  color: #464646;
}

/* MENU */

.menu__button--open {
  font-size: 24px;
  color: #243e63;
  transition: all 300ms ease;
  cursor: pointer;
  display: none;
}

.menu__button--open:hover {
  color: #6415ff;
}

.menu__button--close {
  position: absolute;
  font-size: 24px;
  color: #243e63;
  top: 11px;
  right: 15px;
  transition: all 300ms ease;
  cursor: pointer;
}

.menu__button--close:hover {
  color: #6415ff;
}

.menu {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 32px;
  margin: 24px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background-color: #ffffff;
  visibility: hidden;
  transform: translateX(100%) translateZ(0px);
  transition: all 700ms cubic-bezier(.79, -0.19, .3, 1.47);
}

.menu--open .menu {
  visibility: visible;
  transform: translateX(0%) translateZ(0px);
}

.menu__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu__link {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-bottom: 4px;
  line-height: 28px;
  color: #1a202c;
}

.menu__link--signup {
  padding: 12px 32px;
}

/* HEADER */

.header__container {
  padding: 96px 32px;
}

.header__row {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.header__description {
  width: 40%;
}

.header__title {
  font-size: 48px;
  line-height: 60px;
}

.header__para {
  margin: 32px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: normal;
  color: #243e63;
}

.header__img {
  max-width: 700px;
}

.header__input {
  width: 90%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease;
}

.header__input--email {
  width: 100%;
  border: 2px solid;
  border-radius: 9999px;
  border-color: rgba(36, 62, 99, 0.2);
  padding: 16px 192px 16px 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  opacity: 0.6;
  transition: all 300ms ease;
}

.header__input--email:focus {
  border-color: rgba(100, 21, 255, 1);
}

.header__input--email:hover {
  border-color: rgba(36, 62, 99, 0.5);
  cursor: text;
}

.header__button {
  position: absolute;
  margin: 8px;
  /* max-width: 160px; */
  font-size: 16px;
  right: 0;
  padding: 16px 32px;
}

.header__trusted--title {
  margin-top: 80px;
  font-size: 12px;
  font-weight: 800;
  opacity: 0.5;
  letter-spacing: 0.6px;
}

.header__trusted--wrapper {
  margin: 16px 0;
}

.header__trusted--img {
  width: 80%;
  opacity: 0.5;
}

/* WELCOME */

.welcome__container {
  padding-top: 96px;
  text-align: center;
}

.welcome__row {
  width: 100%;
  margin: 0 auto;
}

.welcome__heading {
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)), url(./assets/image2.webp);
  background-size: cover;
  background-position: 50%;
  padding: 150px;
}

.welcome__title {
  font-size: 16px;
  letter-spacing: 1.6px;
}

.welcome__subtitle {
  margin-top: 12px;
  font-size: 32px;
  letter-spacing: -0.2px;
  font-weight: 400;
}

.welcome__para {
  width: 100%;
  max-width: 432px;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 18px;
  font-weight: 500;
}

/* ABOUT US */

.about-us__container {
  padding: 96px;
  text-align: center;
}

.about-us__row {
  width: 100%;
  max-width: 1280px;
  margin: 16px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-us__right-column {
  text-align: right;
  max-width: 576px;
  margin-left: 48px;
}

.about-us__heading {
  width: 100%;
  padding: 32px;
  text-align: right;
}

.about-us__title {
  font-size: 16px;
  letter-spacing: 1.6px;
}

.about-us__subtitle {
  margin-top: 24px;
  font-size: 32px;
  letter-spacing: -0.2px;
  font-weight: 400;
}

.about-us__para {
  width: 100%;
  max-width: 576px;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 18px;
  font-weight: 500;
}

.about-us__img {
  width: 100%;
  max-width: 640px;
  max-height: 400px;
  background-image: url('assets/image3.webp');
  border: 1px solid rgba(124, 139, 161, 0.2);
  border-radius: 8px;
}

.opening-times__times {
  width: 100%;
  margin: 0 auto;
  margin-left: 12px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
}

/* QUALITY */

.quality__container {
  padding: 96px 0;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)), url(./assets/image1.webp);
  background-size: cover;
}

.quality__row {
  width: 100%;
  max-width: 1280px;
  margin: 16px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quality__left-column {
  text-align: left;
  max-width: 576px;
  margin-right: 48px;
}

.quality__title {
  font-size: 16px;
  letter-spacing: 1.6px;
}

.quality__subtitle {
  margin-top: 24px;
  font-size: 32px;
  letter-spacing: -0.2px;
  font-weight: 400;
}

.quality__para {
  width: 100%;
  line-height: 32px;
  max-width: 576px;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 500;
}

.quality__button {
  margin-top: 24px;
  font-size: 14px;
  padding: 12px 32px;
}

.quality__img {
  width: 100%;
  max-width: 640px;
  max-height: 400px;
  border: 1px solid rgba(124, 139, 161, 0.2);
  border-radius: 8px;
}

.our-menu--button {
  font-size: 18px;
  padding: 12px 32px;
}

/* FIND US */

.find-us__container {
  padding: 96px;
  text-align: center;
}

.find-us__row {
  width: 100%;
  max-width: 1280px;
  margin: 16px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.find-us__left-column {
  text-align: center;
  max-width: 576px;
  margin-right: 48px;
  display: flex;
  flex-direction: column;
}

.find-us__heading {
  width: 100%;
  padding: 32px;
  text-align: center;
}

.find-us__title {
  font-size: 16px;
  letter-spacing: 1.6px;
}

.find-us__subtitle {
  margin-top: 24px;
  font-size: 32px;
  letter-spacing: -0.2px;
  font-weight: 400;
}

.find-us__para {
  width: 100%;
  max-width: 576px;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 18px;
  font-weight: 500;
}

.find-us__img-wrapper {
  width: 100%;
  max-width: 640px;
  max-height: 400px;
}

.find-us__img {
  height: auto;
  width: 100%;
}

.find-us__map {
  border: 1px solid rgba(124, 139, 161, 0.2);
  border-radius: 8px;
}


/* OPENING TIMES */

.opening-times__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.opening-times__day {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.opening-times__days {
  width: 100%;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
}

.opening-times__left-column {
  text-align: left;
}

 /* MAP */

 .find-us__map {
  width: 640px;
  height: 400px;
 }

/* FOOTER */

.footer__container {
  padding-top: 32px;
  text-align: center;
}

.footer__row {
  width: 100%;
  margin: 0 auto;
  align-items: center;
  background-color: #ee5f1d;
  padding: 64px 32px;
}

.footer__lists {
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f7fafc;
  margin: 0 auto;
  border-bottom: 2px solid;
  padding-bottom: 64px;
  border-color: rgba(229, 231, 235, 0.1);
}

.footer__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.footer__list--title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.footer__links {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer__link--wrapper {
  margin-top: 12px;
}

.footer__link {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.footer__bottom {
  max-width: 1280px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.footer__icon--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.6px;
}

.footer__logo {
  width: 32px;
  margin-right: 8px;
}

.footer__icon--name {
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1px;
}

.footer__copyright {
  font-size: 16px;
  line-height: 24px;
  color: #f7fafc;
}

.footer__social--links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__social--link {
  background-color: #f7fafc;
  padding: 8px;
  margin-left: 16px;
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-margin {
  margin: 0;
}

.footer__social--link--img {
  color: #1a202c;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

  /* Enhanced Mobile Styles */
@media (max-width: 480px) {

  body {
    max-width: 480px;
  }
  .nav__row {
    max-width: 480px;
    padding: 0px 24px;
  }
  

/* WELCOME */

.welcome__container {
  padding-top: 48px;
}

.welcome__heading {
  display: flex;
  flex-direction: column;
  padding: 75px;
}

.welcome__subtitle {
  max-width: 480px;
  width: 100%;
}

.welcome__para {
  max-width: 480px;
}

/* ABOUT US */

.about-us__container {
  padding: 48px;  
}

.about-us__row {
  flex-direction: column-reverse;
}

.about-us__right-column {
  text-align: center;
  margin: auto;
}

.about-us__heading {
  text-align: center;
  padding: 0px;
}

.about-us__subtitle {
  font-size: 24px;
}

.about-us__para {
  font-size: 14px;
  line-height: initial;
}

.about-us__img-wrapper {
  margin-top: 16px;
}

/* QUALITY */

.quality__container {
  padding: 48px;
}

.quality__row {
  display: flex;
  flex-direction: column;
}

.quality__left-column {
  margin: initial;
  text-align: center;
}

.quality__subtitle {
  font-size: 24px;
}

.quality__para {
  font-size: 14px;
  line-height: initial;
}

.menu__button {
  margin-top: 16px;
}

/* FIND US */

  .find-us__container {
    padding: 48px;
  }

  .find-us__row {
    margin: auto;
    flex-direction: column;
  }

  .find-us__heading {
    padding: 16px;
    padding-top: 0px;
  }

  .find-us__left-column {
    margin: 0 ;
  }

  .opening-times__container {
    text-align: center;
  }

  .opening-times__left-column {
    text-align: left;
  }

  .opening-times__right-column {
    text-align: right;
  }

  .find-us__img-wrapper {
    padding-top: 36px;
    width: initial;
    max-width: initial;
    max-height: initial;
  }

  .find-us__map {
    width: 43vh;
    height: 25vh;
  }

  /* FOOTER */

  .footer__row {
    padding: 32px;
  }

  .footer__copyright {
    font-size: 14px;
  }

  .footer__bottom {
    justify-content: center;
  }


/* MENU */

.menu__container-carousel {
  padding: 16px 64px;
  text-align: center;
}

}

